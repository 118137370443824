import React from 'react';

const SearchBar = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <input 
        type="text" 
        style={{
          width: '60%',
          padding: '15px',
          borderRadius: '24px',
          border: '1px solid #dfe1e5',
          outline: 'none',
          fontSize: '16px'
        }}
      />
      <div style={{ marginTop: '20px' }}>
        <button style={buttonStyle}>Add Expense</button>
        <button style={buttonStyle}>Get Insights</button>
      </div>
    </div>
  );
};

const buttonStyle = {
  margin: '10px',
  padding: '10px 20px',
  borderRadius: '4px',
  border: '1px solid #f8f9fa',
  backgroundColor: '#f8f9fa',
  fontSize: '14px',
  cursor: 'pointer'
};

export default SearchBar;
