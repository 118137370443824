import React from 'react';
import MoneyWentWhereLogo from './MoneyWentWhereLogo';
import SearchBar from './SearchBar';

function App() {
  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '80vh', // Full viewport height
      textAlign: 'center', 
      fontFamily: 'Arial, sans-serif',
    }}>
      <MoneyWentWhereLogo />
      <div style={{ marginTop: '20px' , width: '100%'}}>
        <SearchBar />
      </div>
    </div>
  );
}

export default App;
