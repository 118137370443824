import React from 'react';

const GoogleLogo = () => {
  return (
    <div style={{
      marginTop: '10vh', // Use viewport height for margin
      marginBottom: '2vh', // Use viewport height for margin
      fontSize: 'clamp(30px, 10vw, 60px)', // Font size scales with viewport width but stays within 30px to 60px
      fontWeight: 'bold',
      fontFamily: 'Arial, sans-serif',
      textAlign: 'center' // Center the text horizontally
    }}>
      <span style={{ color: '#4285F4' }}>M</span>
      <span style={{ color: '#EA4335' }}>o</span>
      <span style={{ color: '#FBBC05' }}>n</span>
      <span style={{ color: '#34A853' }}>e</span>
      <span style={{ color: '#EA4335' }}>y</span>
      <span style={{ color: '#4285F4' }}>W</span>
      <span style={{ color: '#FBBC05' }}>e</span>
      <span style={{ color: '#34A853' }}>n</span>
      <span style={{ color: '#EA4335' }}>t</span>
      <span style={{ color: '#4285F4' }}>W</span>
      <span style={{ color: '#FBBC05' }}>h</span>
      <span style={{ color: '#34A853' }}>e</span>
      <span style={{ color: '#EA4335' }}>r</span>
      <span style={{ color: '#4285F4' }}>e</span>
    </div>
  );
};

export default GoogleLogo;
